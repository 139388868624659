dialog {
  font: $base-font-weight #{$small-font-size}/#{$base-line-height} $util-font-family;
  border: none;
  border-radius: 1em;
  padding: 1em;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  color: $brand-color;
  width: 80%;
  max-width: 800px;
}
dialog a, dialog a:visited {
  color: $grey-color-dark;
}
#consent-dialog::backdrop {
  backdrop-filter: blur(10px) grayscale(30%) brightness(70%);
  -webkit-backdrop-filter: blur(10px) grayscale(30%) brightness(70%);
}
dialog + .backdrop {
  background-image: url('/images/2.jpg');
  background-size: cover;
}
@supports (backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)) {
  dialog + .backdrop {
    background-image: none;
    backdrop-filter: blur(10px) grayscale(30%) brightness(70%);
    -webkit-backdrop-filter: blur(10px) grayscale(30%) brightness(70%);
  }  
}
input[type="checkbox"] {
  -ms-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -o-transform: scale(1.25);
  transform: scale(1.25);
}
button {
  cursor: pointer;
  font: $base-font-weight #{$small-font-size}/#{$base-line-height} $util-font-family;
  /*font-size: 0.875rem;*/
  line-height: 2;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  border: 0;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
label {
  display: inline-block;
  font-size: 1.5rem;
}
.error {
  color: rgba(219, 109, 6, 0.76);
  animation: wiggle 2.5s infinite;
}
button.primary {
  background-color: $brand-color;
  color: #fff; 
}
button.secondary {
  background-color: $grey-color-light;
  color: $grey-color;
}
hr {
  height:1px;
  border-width:0;
  color:$brand-color;
  background-color:$brand-color;
}
@keyframes wiggle {
    0% { transform: rotate(0deg); }
    5% { transform: rotate(3deg); }
   15% { transform: rotate(-3deg); }
   20% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}
